<ng-container *ngIf="list$ | async as list; else loading" [class]="(editMode ? 'editMode' : '')">
  <div class="title">
    <h2>{{list?.title}}</h2>
    <span>
      <button mat-icon-button (click)="resetList()"><mat-icon>restart_alt</mat-icon></button>
      <button mat-icon-button (click)="editMode = !editMode"><mat-icon>{{editMode ? "check" : "edit"}}</mat-icon></button>
    </span>
  </div>
  <mat-list cdkDropList cdkDropListLockAxis="y" (cdkDropListDropped)="onDrop($event)">
    <ng-container *ngIf="!editMode; else editItem">
      <mat-list-item *ngFor="let item of this.listItems">
        <mat-checkbox [checked]="item.done ?? false" (change)="onStatusToggle(item.id!)">{{ item.title }}</mat-checkbox>
      </mat-list-item>
    </ng-container>
    <ng-template #editItem>
      <div class="editMode">
        <mat-list-item cdkDrag *ngFor="let item of this.listItems">
          <mat-icon cdkDragHandle style="padding-right: 0.5em; cursor: grab;">drag_handle</mat-icon>
          {{ item.title }}
          <button mat-icon-button *ngIf="editMode" (click)="onDeleteChecklistItem(item.id!)"><mat-icon>delete</mat-icon></button>
        </mat-list-item>
      </div>
      <mat-list-item>
        <form style="width: 100%;">
          <mat-form-field style="width: 100%;">
            <mat-label>New Item</mat-label>
            <input matInput type="text" #title>
            <button mat-icon-button matSuffix (click)="onAddChecklistItem()" [attr.aria-label]="'Add new item'" >
              <mat-icon>add</mat-icon>
            </button>
          </mat-form-field>
        </form>
      </mat-list-item>
    </ng-template>
  </mat-list>
</ng-container>

<ng-template #loading>
  <mat-progress-spinner></mat-progress-spinner>
</ng-template>
