<div class="sidenav">
  <mat-toolbar>
    <div class="flex-between">
      <span>Lists</span>
      <span><button mat-icon-button (click)="editMode = !editMode"><mat-icon>{{editMode ? "check" : "edit"}}</mat-icon></button></span>
    </div>
  </mat-toolbar>
  <mat-nav-list>
    <mat-list-item *ngFor="let list of this.lists" mat-list-item (click)="onSelectChecklist(list.id!)" [ngClass]="(isIdActive(list.id!) ? 'mat-list-item-focus' : '')">
      <span class="navbar-item"><span><mat-icon *ngIf="isIdActive(list.id!)">arrow_right</mat-icon>
      {{list.title}}</span>
      <mat-icon *ngIf="editMode" (click)="onClickDelete(list.id!, $event)">delete</mat-icon></span>
    </mat-list-item>
  </mat-nav-list>
  <div class="bottom" *ngIf="editMode">
    <h3>Add new list</h3>
    <form>
      <mat-form-field>
        <mat-label>Title</mat-label>
        <input matInput type="text" #title>
        <button mat-icon-button matSuffix (click)="onClickAddChecklist()" [attr.aria-label]="'Add new list'" >
          <mat-icon>add</mat-icon>
        </button>
      </mat-form-field>
    </form>
  </div>
</div>
