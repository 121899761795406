<mat-sidenav-container class="sidenav-container" autosize>
  <mat-sidenav #drawer class="sidenav" fixedInViewport
      [attr.role]="mobileMenu ? 'dialog' : 'navigation'"
      [mode]="mobileMenu ? 'over' : 'side'"
      [opened]="true">
    <app-sidenav [lists]="this.checklists$ | async" (listChanged)="mobileMenu && drawer.close();" [currentListId]="currentListId"></app-sidenav>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="mobileMenu">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span>{{title}}</span>
    </mat-toolbar>
    <div class="mainContent">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

